<template>
  <div class="header">
    <van-image :src="require('./assets/banner.png')" width="100%" fit="cover"></van-image>
  </div>
  <div class="tabs">
    <div :class="['item', item.code, { active: active == item.code }]" @click="changeTab(item)" v-for="item of games"
      :key="item.code">
    </div>
  </div>
  <div class="latest">
    <div class="current">
      <div class="lotter">
        <img :src="require(`./assets/logo_${active}.png`)" class="logo">
        <!-- <div class="name">{{ games[active].name }}</div> -->
      </div>

      <div class="mid">
        <div class="curr">
          <div class="issue">第<span class="value">{{ game.countDown > 0 ? game.issue : game.nextIssue }}</span>期</div>
          <!-- <div class="pages">
            <button class="btn-prev" @click="issue--"></button>
            <input type="text" :value="issue">
            <button :class="['btn-next', { disabled: issue >= game.issue }]"
              @click="issue < game.issue ? issue++ : ''"></button>
          </div> -->
        </div>
        <div class="next" v-show="game.countDown">
          <!-- 距离下一期开奖 -->
          <FilpClock :seconds="game.countDown" @finish="onFinish" />
        </div>
        <div class="loading" v-show="!game.countDown">
          <img :src="require('./assets/icon-loading.png')">
          <span>正在开奖...</span>
        </div>

      </div>
      <button class="btn" @click="setGGLModel()">咪牌模式</button>
    </div>

  </div>
  <div class="result">
    <div :class="['balls', active, { open: game.countDown == 0 }]">
      <template v-for="(num, n) of game.balls" :key="n">
        <div :class="['ball', 'ball_' + num]">
          <div class="num-list">
            <div :class="['num', 'num_' + i]" v-for="i of games[active].balls" :key="i">{{ i }}</div>
          </div>
        </div>
        <div class="unit" v-show="n != game.balls.length - 1">+</div>
      </template>
      <div class="unit">=</div>
      <div :class="['res', getBallColor(game.total)]">{{ game.total }}</div>
    </div>
    <div class="total">
      <div :class="['res', game.bigOrSmall.label]"></div>
      <div :class="['res', game.oddOrEven.label]"></div>
    </div>
  </div>

  <div class="tips">
    仅可使用数字币娱乐，请尊重当地法律法规！
  </div>

  <div class="hash" v-show="game.hash">
    {{ game.hash }}
  </div>

  <div class="tabbar">
    <div :class="['item', { active: tabIndex == 0 }]" @click="tabIndex = 0">结果</div>
    <div :class="['item', { active: tabIndex == 1 }]" @click="tabIndex = 1">走势图</div>
  </div>

  <div class="tab-content">
    <div class="table border" v-show="tabIndex == 0">
      <div class="table-header">
        <div class="col">期号</div>
        <div class="col">时间</div>
        <div class="col">开奖号码</div>
        <div class="col" v-show="active == 'dd28'">总和</div>
        <div class="col" v-show="active != 'dd28'">区块号</div>
      </div>
      <div class="table-body">
        <div class="row" v-for="item of list" :key="item.issue">
          <div class="col">{{ item.issue }}</div>
          <div class="col">{{ item.otm }}</div>
          <div class="col font-din">{{ item.balls.join(' ') }}</div>
          <div class="col font-din" v-show="active == 'dd28'">{{ item.total }}</div>
          <div class="col" v-show="active != 'dd28'">
            <a class="link" :href="`https://tronscan.org/#/block/${item.block}`" target="_blank">{{ item.block }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="table border combo-header" v-show="tabIndex == 1">
      <div class="table-header" style="border-bottom: none;">
        <div class="col">期号</div>
        <div class="col w-36">值</div>
        <div class="col w-70">
          <div class="res">大小</div>
          <div class="res">单双</div>
        </div>
        <div class="col w-160">
          <div class="res">大单</div>
          <div class="res">大双</div>
          <div class="res">小单</div>
          <div class="res">小双</div>
        </div>
      </div>
      <div class="table-body">
        <div class="row" v-for="item of list" :key="item.issue">
          <div class="col">{{ item.issue }}</div>
          <div class="col w-36 font-din">{{ item.total }}</div>
          <div class="col w-70">
            <div class="res"></div>
            <div class="res">
              <span :class="item.bigOrSmall.label"></span>
            </div>
            <div class="res"></div>
            <div class="res">
              <span :class="item.oddOrEven.label"></span>
            </div>
            <div class="res"></div>
          </div>
          <div class="col w-160">
            <div class="res" v-for="label of ['bigOdd', 'bigEven', 'smallOdd', 'smallEven']" :key="label">
              <span :class="label == item.bsOrOe.label ? item.bsOrOe.label : ''"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn-more" @click="showMore = !showMore">{{ showMore ? '收起' : '更多' }}</button>
  </div>

  <div class="footer">
    <div class="title">全网最快最舒适开奖网站</div>
    <div class="desc">
      免责声明：本站所有数据与资料仅供您参考，请勿用于违法用途。
    </div>
  </div>
  <van-popup v-model:show="showGGL" :style="popupStyle" position="center" closeable
    :close-icon="require('./assets/ico_close.png')" close-icon-position="bottom-center">
    <div class="ggl-container" >
      <div class="issue">第<span class="value">{{ params.issue }}</span>期开奖结果</div>
      <div class="res-warpper">
        <div class="res">
          <template v-for="(num, n) of params.res.balls" :key="n">
            <span class="unit" v-show="n != 0">+</span>
            <span class="num">{{ num }}</span>
          </template>
          <span class="unit">=</span>
          <span class="num">{{ params.res.total }}</span>
        </div>
        <vueEaser ref="canvas" :status="params.status" :completeFunction="complete" :progressFunction="progress" />
        <!-- <GuaGuaCard ref="canvas" :status="params.status" :position="position" /> -->
      </div>
      <div class="bottom" v-show="model != 'history'">
        <div class="next">截止第 <span class="value">{{ game.nextIssue }}</span>期开奖:
          <span class="value">
            <van-count-down :time="game.countDown * 1000" />
          </span>
          秒
        </div>
        <button class="btn-refresh" @click="refreshGGL()">刷新</button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getHistoryCnd, getOthersHistory, getGameStatus, getComboResult, getOthersGameLatest, getGameLatestCnd, formatGame } from '@/api'
// import http from '@/request'
// import GuaGuaCard from '@/components/ggk.vue'
import FilpClock from '@/components/FlipClock.vue'

import vueEaser from "@/components/vue-eraser.vue";
import { qs } from '@/utils'
Math.sum = function (...args) {
  return args.reduce((prev, curr) => {
    return prev += Number(curr)
  }, 0)
}
const dd28Type = {
  max: 14,
  maxNum: 9,
  len: 3,
  balls: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  colors: {
    red: [3, 6, 9, 12, 15, 18, 21, 24],
    green: [1, 4, 7, 10, 16, 19, 22, 25],
    blue: [2, 5, 8, 11, 17, 20, 23, 26]
  }
}
const defGame = () => {
  return {
    balls: [0, 0, 0],
    bigOrSmall: { label: '', color: '' },
    oddOrEven: { label: '', color: '' },
    countDown: 0,
    issue: '000000',
    nextIssue: '000000',
    openTime: '',
    serverTime: '',
    total: 0,
    hash: '',
    block: ''
  }
}
export default {
  name: "App",
  components: {
    // GuaGuaCard,
    FilpClock,
    vueEaser
  },
  data() {
    return {
      active: sessionStorage.getItem('active') || 'dd28',
      issue: '',
      game: defGame(),
      games: {
        dd28: {
          code: 'dd28',
          name: '加拿大28',
          desc: 'JINADA28',
          ...dd28Type,
        },
        game_pc28: {
          code: 'game_pc28',
          name: '以太坊28',
          desc: 'YITAIFANG28',
          ...dd28Type,
        },
        game_ssc: { code: 'game_ssc', name: '以太坊时时彩', desc: 'YITAIFANGSSC', max: 23, maxNum: 9, balls: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], len: 5 },
        game_k3: { code: 'game_k3', name: '以太坊快三', desc: 'YITAIFANGK3', max: 11, maxNum: 6, balls: [1, 2, 3, 4, 5, 6], len: 3 }
      },
      history: {
        dd28: [],
        game_pc28: [],
        game_ssc: [],
        game_k3: []
      },
      tabIndex: 0,
      loading: { dd28: false, others: false },
      showMore: false,
      showGGL: false,
      timer: null,
      params: {
        issue: '',
        code: '',
        status: 1,
        res: { balls: [], total: '' }
      },
      showCanvas: false,
      model: 'normal',
      isDrag: false,
      position: { x: 0, y: 0 },
      lotterAnimationTimer: {},
      origin: {
        dd28: {
          "last": {
            "dd28": "",
            "issue": 0,
            "otm": 0
          },
          "now": {
            "issue": 0,
            "open_time": 0,
            "server_time": 0,
            "time_left": 0
          }
        },
        others: {
          "last": {
            "game_pc28": "",
            "game_ssc": "",
            "game_k3": "",
            "issue": 0,
            "otm": 0
          },
          "now": {
            "issue": 0,
            "open_time": 0,
            "server_time": 0,
            "time_left": 0
          }
        }
      }
    };
  },
  computed: {
    list() {
      if (this.showMore) {
        return this.history[this.active]
      } else {
        return this.history[this.active].slice(0, 20)
      }
    },
    popupStyle() {
      return {
        background: 'transparent',
        'overflow-y': 'unset'
      }
    }
  },
  watch: {
    issue(value) {
      const item = this.history[this.active].find(item => item.issue == value)
      if (item) {
        this.game.balls = item.balls
        this.game.total = item.total
        const combo = getComboResult(item.total, this.games[this.active].max)
        this.game.bigOrSmall = combo.bigOrSmall
        this.game.oddOrEven = combo.oddOrEven
      }
    },
    showGGL(show) {
      if (show) {
        // this.$refs.canvas.init()
      } else {
        this.params.status = -1
        this.$refs.canvas.reset()
      }
    },
    active: {
      handler(value) {
        sessionStorage.setItem('active', value)
        let game = {};
        let _conf = this.games[value]
        let halfNumber = _conf.maxNum / 2
        if (value == 'dd28') {
          game = formatGame(this.origin.dd28, value)
        } else {
          game = formatGame(this.origin.others, value)
        }
        this.game.balls = game.balls.map(num => num >= halfNumber ? Number(num) - halfNumber : Number(num) + halfNumber)
        setTimeout(() => {
          this.game = game
          this.issue = this.game.issue
          if (this.game.countDown <= 0) {
            this.startNumberAnimation()
          }
        }, 60)
      }
    }
  },
  created() {
    const issue = qs('issue')
    const code = qs('code')
    if (issue && code) {
      if (this.games[code]) {
        this.active = code
      }
      this.params.issue = issue
      this.params.code = code
      this.showGGL = true
      this.model = 'history'
      this.loadGameStatus()
    }
    this.initGames()
    document.addEventListener("visibilitychange", this.handleVisiable);
    document.οncοntextmenu = function (e) {
      e.preventDefault();
    };
    // window.ontouchstart = function (e) {
    //   e.preventDefault();
    // };

    this.loaderImages()

  },
  methods: {
    complete() { },
    progress() { },
    loaderImages() {
      const imgs = [
        require('./assets/ggk_mask.png'),
        require('./assets/bg_dd28_selected.png'),
        require('./assets/bg_game_pc28_selected.png'),
        require('./assets/bg_game_ssc_selected.png'),
        require('./assets/bg_game_k3_selected.png'),
      ]
      imgs.forEach(img => {
        new Image().src = img
      })
    },
    getRandomResult() {
      const _conf = this.games[this.active]
      return Array(_conf.len).fill(0).map(() => {
        return Math.floor(Math.random() * (_conf.maxNum + 1))
      })
    },
    sleep(s) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, s * 1000)
      })
    },
    handleVisiable(e) {
      if (e.target.visibilityState === "visible") {
        this.initGames()
      }
    },
    getBallColor(value) {
      if (['dd28', 'game_pc28'].includes(this.active)) {
        return ['red', 'green', 'blue'].find(color => dd28Type.colors[color].includes(Number(value))) || ''
      } else if (this.active == 'game_ssc') {
        return value >= 23 ? 'red' : 'blue'
      } else if (this.active == 'game_k3') {
        return value >= 11 ? 'red' : 'blue'
      }
    },
    startNumberAnimation() {
      if (this.game.countDown > 0) {
        clearTimeout(this.lotterAnimationTimer)
        return
      }
      this.game.balls = this.game.balls.map(ball => {
        let _ball = Number(ball)
        if (this.active == 'game_k3') {
          return _ball >= 6 ? 1 : (_ball + 1)
        } else {
          return _ball >= 9 ? 0 : (_ball + 1)
        }
      })
      this.game.total = Math.sum(...this.getRandomResult())
      this.lotterAnimationTimer = setTimeout(() => {
        this.startNumberAnimation()
      }, 80)
    },
    initGGL() {
      this.params.code = this.active
      this.params.res = {  
        balls: this.game.balls,
        total: this.game.total
      }
      if (this.game.countDown > 0) {
        this.params.status = this.game.balls.length > 1 ? 2 : 1
        this.params.issue = this.game.issue
      } else {
        this.params.status = 1
        this.params.issue = this.game.nextIssue
      }
    },
    setGGLModel() {
      this.model = 'GGL'
      this.initGGL()
      this.showGGL = true
    },
    async loadGameLatestCnd(isInc) {
      try {
        this.loading.dd28 = true
        if (isInc) {
          await this.sleep(2)
        }
        const res = await getGameLatestCnd()
        this.origin.dd28 = res
        if (res.now.time_left > 0) {
          if (this.active == 'dd28') {
            this.game = formatGame(res, this.active)
            this.issue = this.game.issue
            if (this.model == 'GGL') {
              this.initGGL()
            }
          }
          this.loadGameHistoryCnd()
        } else if (!this.history.dd28.length) {
          this.loadGameHistoryCnd()
        }
      } finally {
        this.loading.dd28 = false
      }
    },
    async loadOthersGameLatest(isInc) {
      try {
        this.loading.others = true
        if (isInc) {
          await this.sleep(2)
        }
        const res = await getOthersGameLatest()
        this.origin.others = res
        if (res.now.time_left > 0) {
          if (this.active != 'dd28') {
            this.game = formatGame(res, this.active)
            this.issue = this.game.issue
            if (this.model == 'GGL') {
              this.initGGL()
            }
          }
          this.loadOthersGameHistory()
        } else if (!this.history[this.active].length) {
          this.loadOthersGameHistory()
        }
      } finally {
        this.loading.others = false
      }
    },
    async initGames() {
      await Promise.all([this.loadGameLatestCnd(), this.loadOthersGameLatest()])
      this.game = formatGame(this.active == 'dd28' ? this.origin.dd28 : this.origin.others, this.active)
      this.issue = this.game.issue
      if (this.game.countDown <= 0) {
        this.startNumberAnimation()
      }
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.origin.dd28.now.time_left > 0) {
          this.origin.dd28.now.time_left--
        } else if (!this.loading.dd28) {
          this.loadGameLatestCnd(true)
        }
        if (this.origin.others.now.time_left > 0) {
          this.origin.others.now.time_left--
        } else if (!this.loading.others) {
          this.loadOthersGameLatest(true)
        }
      }, 1000)
    },
    async loadGameStatus() {
      const res = await getGameStatus(this.params.code, this.params.issue)
      if (res.issue == this.params.issue) {
        this.params.status = res.status
        if (res.status != 2) {
          setTimeout(() => {
            if (this.showGGL) {
              this.loadGameStatus()
            }
          }, 3000)
        } else {
          const balls = res[this.params.code].split(',')
          const total = balls.reduce((prev, curr) => prev += Number(curr), 0)
          this.params.res = {
            balls,
            total
          }
        }
      }
    },
    async refreshGGL() {
      this.params.status = 5
      this.params.res = { balls: [], total: '' }
      if (this.model == 'history') {
        this.loadGameStatus()
      } else {
        this.initGGL()
        this.$refs.canvas.reset()
      }
    },
    async loadGameHistoryCnd() {
      try {
        const res = await getHistoryCnd()
        if (Array.isArray(res)) {
          this.history.dd28 = res
        }
      } catch (error) {
        this.loadGameHistoryCnd()
      }
    },
    async loadOthersGameHistory() {
      try {
        const { game_k3, game_pc28, game_ssc } = await getOthersHistory()
        this.history.game_k3 = game_k3
        this.history.game_pc28 = game_pc28
        this.history.game_ssc = game_ssc
      } catch (error) {
        this.loadOthersGameHistory()
      }
    },
    async changeTab(item) {
      this.active = item.code
    },
    onFinish() {
      if (this.model == 'GGL') {
        this.params.issue = this.game.nextIssue
        this.params.status = 1
        this.isDrag = false
      }
      this.game.countDown = 0
      this.startNumberAnimation()
    },
    touchStart(e) {
      if (this.params.status != 2) return
      this.position = this.getCurPosition(e)
      this.isDrag = true
    },
    touchMove(e) {
      if (this.isDrag) {
        this.position = this.getCurPosition(e)
      }
    },
    touchEnd() {
      this.isDrag = false
    },
    getCurPosition(e) {
      const x = e.targetTouches[0].clientX
      const y = e.targetTouches[0].clientY
      const map = this.$refs.canvas.$el.getBoundingClientRect()
      return {
        x: x - map.left,
        y: y - map.top
      }
    }
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "DinPro";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/D-DIN.woff") format("woff");
}

@keyframes active {
  from {
    transform: scale(0.3);
  }

  to {
    transform: scale(1);
  }
}

html,
body {
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  background: #f6f6f6;
}

.link {
  color: #1110D0;
  text-decoration: underline;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #28303f;
  overflow: hidden auto;
  margin: 0 auto;
  max-width: 414px;
}

$color-blue: #0b43d7;
$color-red: #ee742f;
$color-red2: #e63442;


@mixin flex($justify: center, $alignItems: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justify;
}

@mixin border($witdh: 1px, $color: #cadcfa) {
  border: $witdh solid $color;
}

.van-popup--center {
  max-width: 100vw !important;
}

.van-popup__close-icon--bottom-center {
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
}

.font-din {
  font-family: "DinPro";
  font-weight: bold;
}

.header {
  padding: 10px 12px 0;
  font-size: 0
}

.tabs {
  @include flex(space-between);
  margin: -12px 22px 10px;

  .item {
    position: relative;
    width: 78px;
    height: 84px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @include flex(center, center, column);

    &.dd28 {
      background-image: url('./assets/bg_dd28.png');
    }

    &.game_pc28 {
      background-image: url('./assets/bg_game_pc28.png');
    }

    &.game_ssc {
      background-image: url('./assets/bg_game_ssc.png');
    }

    &.game_k3 {
      background-image: url('./assets/bg_game_k3.png');
    }

    &.active {
      height: 96px;
      background-size: 100% 100%;
      animation: active 0.3s;
      animation-fill-mode: forwards;
      z-index: 10;

      .next-cd {
        @include flex;
      }
    }


    &.active.dd28 {
      background-image: url('./assets/bg_dd28_selected.png');
    }

    &.active.game_pc28 {
      background-image: url('./assets/bg_game_pc28_selected.png');
    }

    &.active.game_ssc {
      background-image: url('./assets/bg_game_ssc_selected.png');
    }

    &.active.game_k3 {
      background-image: url('./assets/bg_game_k3_selected.png');
    }
  }
}

.latest {
  @include flex(space-between, flex-start, column);
  margin: 8px 12px;
  padding: 10px 10px 10px;
  @include border;
  border-radius: 4px;
  background: #fff;
  box-sizing: content-box;

  .current {
    @include flex(flex-start, center, row);
    width: 100%;

    .lotter {
      @include flex(center, center, column);
      width: 60px;

      .logo {
        width: 58px;
        height: 58px;
      }
    }

    .mid {
      flex: 1;
      @include flex(center, center, column);

      .issue {
        font-size: 16px;

        .value {
          color: #E63442;
          padding: 0 2px;
        }
      }

      .pages {
        @include flex;

        .btn-prev,
        .btn-next {
          background: url("./assets/ico_prev.png");
          background-size: 4px 8px;
          background-position: center;
          background-repeat: no-repeat;
          width: 18px;
          height: 14px;
          line-height: 14px;
          text-align: center;
          @include border(1px, #757575);
          border-radius: 4px;
        }

        .btn-next {
          background-image: url("./assets/ico_next.png");

          &.disabled {
            opacity: 0.5;
          }
        }

        input {
          width: 60px;
          height: 14px;
          line-height: 14px;
          text-align: center;
          border-radius: 4px;
          padding: 0;
          margin: 0 2px;
          outline: 0;
          font-size: 12px;
          color: #28303f;
          @include border(1px, #757575);
        }
      }
    }

    .btn {
      background: linear-gradient(180deg, #FF8F4A 0%, #EC5829 100%);
      width: 85px;
      height: 26px;
      border: 0;
      font-size: 12px;
      color: #fff;
      border-radius: 2px;
    }
  }

  .next {
    @include flex(flex-end, center, column);
  }

  .loading {
    width: 100%;
    @include flex;
    font-size: 14px;
    color: #E63442;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.next-warpper {
  @include flex(center, center, column);
  margin: 8px 12px;
  @include border;
  border-radius: 4px;
  background: #fff;
  height: 82px;

  .next {
    width: 100%;
    @include flex(center, center, column);

    .ico-fav {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }

  .loading {
    width: 100%;
    @include flex;
    font-size: 14px;
    color: #E63442;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.result {
  @include flex;
  margin: 8px 12px;
  padding: 20px 15px;
  @include border;
  border-radius: 4px;
  background: #fff;

  .balls {
    flex: 1;
    @include flex;

    .ball,
    .num,
    .res {
      font-family: "DinPro";
      font-weight: bold;
      @include flex;
      position: relative;
      width: 32px;
      height: 32px;
      font-size: 24px;
      border-radius: 2px;
      color: #fff;

      background-color: #53B2B0;
    }

    .unit {
      padding: 0 5px;
    }

    .num-list {
      position: relative;
      @include flex(center, center, column);
      transition: all 0.2s;

      .num {
        width: 32px;
        height: 32px;
        background-color: #53B2B0;
      }
    }

    .ball {
      align-items: flex-start;
      overflow: hidden;

      @for $i from 0 to 10 {
        &.ball_#{$i} .num-list {
          transform: translateY(-#{ $i * 32 }px);
        }
      }
    }

    &.open .ball_0 .num-list {
      transition: none;
    }

    // $numColors: #5082F5, #55B235, #FB9275, #FF6D6E, #45C5A6, #69CF8B, #C089FF, #DD82E0, #E59700, #5082F6;

    // @each $color in $numColors {
    //   $index: index($numColors, $color);

    //   .num_#{ $index - 1 } {
    //     background-color: $color;
    //   }
    // }

    .res {
      // background: linear-gradient(180deg, #EE782F 0%, #EC5829 100%);
      background: #4A505F;

      &.green {
        background-color: #4AA45A;
      }

      &.red {
        background-color: #EB4C3A;
      }

      &.blue {
        background-color: #3D8AEF;
      }
    }
  }

  .game_ssc {
    padding-right: 10px;

    .unit {
      padding: 0 2px;
    }
  }

  .game_k3 {

    .ball,
    .num {
      color: transparent;
      background-color: transparent !important;
    }

    .num-list {
      // transition: unset;
    }



    @for $i from 1 to 7 {
      .num_#{$i} {
        background-image: url('./assets/dice_#{$i}.png');
        background-size: 100% 100%;
      }
    }

    @for $i from 1 to 7 {
      .ball.ball_#{$i } .num-list {
        transform: translateY(-#{ ($i - 1) * 32 }px);
      }
    }

    &.open .ball_1 .num-list {
      transition: none;
    }
  }


  .total {
    position: relative;
    @include flex;
    padding-left: 15px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 36px;
      width: 1px;
      background: #d7d7d7;
    }

    .res {
      @include flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: #fff;
      background-size: 100% 100%;

      &.big {
        background-image: url('./assets/res_big.png');
      }

      &.small {
        background-image: url('./assets/res_small.png');
      }

      &.odd {
        background-image: url('./assets/res_odd.png');
      }

      &.even {
        background-image: url('./assets/res_even.png');
      }

      +.res {
        margin-left: 10px;
      }
    }
  }
}

.tips {
  @include flex;
  margin: 8px 12px;
  padding: 10px;
  @include border;
  border-radius: 4px;
  background: #fff;
  color: #e63442;
  font-size: 12px;
  font-weight: 500;
}

.hash {
  @include flex;
  margin: 8px 12px;
  padding: 10px;
  @include border;
  border-radius: 4px;
  background: #fff;
  font-size: 12px;
  font-weight: 500;
  word-break: break-all;
  user-select: auto;
}

.tabbar {
  @include flex;
  margin: 8px 12px;

  .item {
    @include flex;
    position: relative;
    flex: 1;
    padding: 5px 0;
    background: #fff;

    &.active {
      background: #e0e0e0;
      color: #000;
      font-weight: bold;
    }

    &.active::before {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-top: 6px solid #e0e0e0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }
}

.tab-content {
  margin: 8px 12px;
  @include flex(center, center, column);

  .table {
    width: 100%;
    @include border(1px, #D7D7D7);
    border-radius: 4px;
    background-color: #fff;
  }

  .table-header {
    opacity: 0.7;
    @include flex;
    // border-bottom: 0.5px solid #d7d7d7;

    .col {
      flex: 1;
      text-align: center;
      padding: 5px 0;
    }

    .w-70 {
      .res:first-child {
        padding-left: 8px;
      }

      .res:last-child {
        padding-right: 8px;
      }
    }
  }

  .table-body {
    .row {
      @include flex;

      .col {
        flex: 1;
        padding: 5px 0;
        height: 20px;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  .table.border {
    .row:not(.border-none) {
      border-top: 0.5px solid #d7d7d7;
    }

    .col+.col {
      border-left: 0.5px solid #d7d7d7;
    }
  }

  .table.combo-header {
    // .row:first-child {
    //   color: #686e79;
    //   opacity: 0.7;
    // }
  }

  .btn-more {
    margin-top: 12px;
    width: 268px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    color: #000;
    opacity: 0.5;
    @include border;
    border-radius: 4px;
    border-color: #454545;
  }
}

.footer {
  border-top: 6px solid #dedede;
  @include flex(center, center, column);
  padding: 12px;

  .title {
    font-weight: 500;
    line-height: 29px;
  }

  .desc {
    font-size: 10px;
    opacity: 0.7;
    text-align: center;
  }
}

.ggl-container {
  position: relative;
  background: url("./assets/ggk.png");
  background-size: 100% 100%;
  width: 375px;
  height: 460px;
  user-select: none;

  .issue {
    color: #fff;
    font-size: 14px;
    position: absolute;
    top: 107px;
    left: 50%;
    transform: translateX(-50%);

    .value {
      color: #231FFD;
      padding: 0 3px;
      font-weight: bold;
    }
  }

  .res-warpper {
    position: absolute;
    top: 233px;
    left: 0;
    width: 375px;
    height: 125px;
    background: #fff;
    @include flex;

    .res {
      font-size: 22px;
      font-weight: bold;

      .num {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 36px;
        text-align: center;
        border: 1px solid #000;
        border-radius: 50%;
      }

      .unit {
        padding: 0 2px;
      }
    }

    .canvas-warpper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      canvas {
        width: 375px;
        height: 125px;
      }
    }
  }

  .bottom {
    position: absolute;
    bottom: 20px;
    left: 12px;
    right: 12px;
    // width: 296px;
    padding: 10px;
    @include flex(space-between);
    border-radius: 8px;
    border: 2px solid #FAE7C8;
    background: linear-gradient(180deg, #FF7628 0%, #FA3500 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

    .next {
      @include flex;
      font-size: 12px;
      color: #fff;

      .value {
        color: #231FFD;
        padding: 0 2px;
        font-weight: bold;
      }
    }

    .btn-refresh {
      width: 72px;
      background: #FFF;
      color: #F9100C;
      font-size: 12px;
      outline: 0;
      border: 0;
      border-radius: 11.5px;
      padding: 3px 10px;
    }
  }
}

.w-70,
.w-160 {
  @include flex;

  .res {
    flex: 1;
    @include flex;

    .big,
    .small,
    .odd,
    .even {
      display: inline-block;
      width: 16px;
      height: 15px;
      background-size: 100% 100%;
    }

    .bigOdd,
    .bigEven,
    .smallOdd,
    .smallEven {
      display: inline-block;
      width: 28px;
      height: 15px;
      background-size: 100% 100%;
    }

    .big {
      background-image: url('./assets/rep_big.png');
    }

    .small {
      background-image: url('./assets/rep_small.png');
    }

    .odd {
      background-image: url('./assets/rep_odd.png');
    }

    .even {
      background-image: url('./assets/rep_even.png');
    }

    .bigOdd {
      background-image: url('./assets/rep_bigOdd.png');
    }

    .bigEven {
      background-image: url('./assets/rep_bigEven.png');
    }

    .smallOdd {
      background-image: url('./assets/rep_smallOdd.png');
    }

    .smallEven {
      background-image: url('./assets/rep_smallEven.png');
    }
  }
}

.w-36 {
  flex: unset !important;
  width: 36px;
}

.w-70 {
  flex: unset !important;
  width: 80px;
}

.w-160 {
  flex: unset !important;
  width: 140px;
}
</style>
