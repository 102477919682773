<template>
    <div class="canvas-warpper">
        <canvas ref="canvas"></canvas>
    </div>
</template>
<script>
export default {
    name: "vue-eraser",
    props: {
        status: {
            type: Number,
            default: -1
        },
        elementId: {
            type: String,
            default: "vueEraser"
        },
        size: {
            type: Number,
            default: 50
        },
        completeRatio: {
            type: Number,
            default: 0.7
        },
        completeFunction: {
            type: Function,
            default: function () {
            }
        },
        progressFunction: {
            type: Function,
            default: function () {
            }
        }
    },
    data() {
        return {
            source: null,
            width: 0,
            height: 0,
            canvas: null,
            canvasPosX: 0,//相对于屏幕偏移
            canvasPosY: 0,
            canvasTouchX: 0,//鼠标位置（减去canvas的边界）
            canvasTouchY: 0,
            isTouchDown: false,
            mobileTouchID: null,//移动端的touch identifier
            ctx: null,
            parts: [],//用于计算面积
            colParts: 0,//一行多好个圆
            numParts: 0,//整个面积上有多少个圆

            ratio: 0,//当前画出比率
            complete: false,//是否达到完成比率
        }
    },
    watch: {
        status: {
            handler() {
                this.isTouchDown = false
                this.reset()
            }
        },
    },
    mounted() {
        let canvasBoundingRect;
        this.canvas = this.$refs.canvas
        this.canvas.width = this.canvas.clientWidth;
        this.canvas.height = this.canvas.clientHeight;
        this.width = this.canvas.clientWidth;
        this.height = this.canvas.clientHeight;
        this.ctx = this.canvas.getContext('2d');
        canvasBoundingRect = this.canvas.getBoundingClientRect();
        this.canvasPosX = canvasBoundingRect.left;
        this.canvasPosY = canvasBoundingRect.top;
        this.init()
    },
    methods: {
        init() {
            let _this = this;
            this.isTouchDown = false
            let img = new Image();
            img.src = require('../assets/ggk_mask.png');
            _this.ctx.fillStyle = '#757575'
            _this.ctx.fillRect(0, 0, _this.canvas.width, _this.canvas.height)
            img.onload = function () {
                _this.ctx.drawImage(img, 0, 0, _this.canvas.width, _this.canvas.height);
                _this.drawStatusText()
                _this.colParts = Math.floor(_this.canvas.width / _this.size);
                _this.numParts = Math.floor(_this.canvas.height / _this.size) * _this.colParts;
                let n = _this.numParts;
                while (n--) _this.parts.push(1);
                // prepare context for drawing operations
                _this.ctx.globalCompositeOperation = "destination-out";//实现橡皮插的效果，后面画的图将让图层透明
                _this.ctx.strokeStyle = 'rgba(255,0,0,255)';
                _this.ctx.lineWidth = _this.size;
                _this.ctx.lineCap = "round";
                _this.bindEvent();
            }
        },
        drawStatusText() {
            const statusText = {
                1: '开奖中...',
                2: '已有结果 请刮涂层',
                3: '未找到游戏',
                5: '正在查询结果...'
            }[this.status] || ''
            this.ctx.font = 'bold 30px Pingfang SC'
            this.ctx.fillStyle = '#FFB522'
            const text = this.ctx.measureText(statusText);
            const x = this.width / 2 - (text.width / 2)
            const y = this.height / 2 + 10
            this.ctx.fillText(statusText, x, y);

        },
        bindEvent() {
            //pc端
            this.canvas.addEventListener("mousedown", this.mouseDown.bind(this), false);
            //移动端
            this.canvas.addEventListener("touchstart", this.touchStart.bind(this), false);
            this.canvas.addEventListener("touchmove", this.touchMove.bind(this), false);
            this.canvas.addEventListener("touchend", this.touchEnd.bind(this), false);
        },
        mouseDown(event) {
            let canvasBoundingRect = this.canvas.getBoundingClientRect();
            this.canvasPosX = canvasBoundingRect.left;
            this.canvasPosY = canvasBoundingRect.top;
            this.canvasTouchX = event.pageX - this.canvasPosX;
            this.canvasTouchY = event.pageY - this.canvasPosY;
            this.evaluatePoint(this.canvasTouchX, this.canvasTouchY);
            this.isTouchDown = this.status == 2;

            this.ctx.beginPath();
            this.ctx.moveTo(this.canvasTouchX - 1, this.canvasTouchY);
            this.ctx.lineTo(this.canvasTouchX, this.canvasTouchY);
            this.ctx.stroke();

            this.mouseMove = this.mouseMove.bind(this);
            this.mouseUp = this.mouseUp.bind(this);
            this.canvas.addEventListener("mousemove", this.mouseMove, false);
            document.addEventListener("mouseup", this.mouseUp, false);
            event.preventDefault();
        },
        mouseMove() {
            let tx = event.pageX - this.canvasPosX,
                ty = event.pageY - this.canvasPosY;
            this.evaluatePoint(tx, ty);
            this.ctx.beginPath();
            this.ctx.moveTo(this.canvasTouchX, this.canvasTouchY);
            this.ctx.lineTo(tx, ty);
            this.ctx.stroke();

            this.canvasTouchX = tx;
            this.canvasTouchY = ty;
            event.preventDefault();
        },
        mouseUp() {
            this.isTouchDown = false;
            this.canvas.removeEventListener("mousemove", this.mouseMove, false);
            document.removeEventListener("mouseup", this.mouseUp, false);
        },
        touchStart(event) {
            if (!this.isTouchDown) {
                let canvasBoundingRect = this.canvas.getBoundingClientRect();
                this.canvasPosX = canvasBoundingRect.left;
                this.canvasPosY = canvasBoundingRect.top;
                let touch = event.changedTouches[0];
                this.isTouchDown = this.status == 2;
                this.mobileTouchID = touch.identifier;
                this.canvasTouchX = event.targetTouches[0].clientX - this.canvasPosX;
                this.canvasTouchY = event.targetTouches[0].clientY - this.canvasPosY;
                this.evaluatePoint(this.canvasTouchX, this.canvasTouchY);
            }
            event.preventDefault();
        },
        touchMove(event) {
            if (this.isTouchDown) {
                let touches = event.changedTouches;
                let n = touches.length;
                while (n--) {
                    if (touches[n].identifier == this.mobileTouchID) {
                        let tx = event.targetTouches[n].clientX - this.canvasPosX;
                        let ty = event.targetTouches[n].clientY - this.canvasPosY;
                        this.evaluatePoint(tx, ty);
                        this.ctx.beginPath();
                        this.ctx.moveTo(this.canvasTouchX, this.canvasTouchY);
                        this.canvasTouchX = tx;
                        this.canvasTouchY = ty;
                        this.ctx.lineTo(tx, ty);
                        this.ctx.stroke();
                        break;
                    }
                }
            }
            event.preventDefault();
        },
        touchEnd(event) {
            if (this.isTouchDown) {
                let touches = event.changedTouches,
                    n = touches.length;
                while (n--)
                    if (touches[n].identifier == this.mobileTouchID) {//为同一根手指的情况下
                        this.isTouchDown = false;
                        event.preventDefault();
                        break;
                    }
            }
        },
        //计算面积
        evaluatePoint(tx, ty) {
            //原理： 首先算出整个面积上面有多少个圆(data.numParts)，生成一个数组(data.parts)， 然后找出当前鼠标位置是哪一个圆，
            //看一下这个圆是否被占，若没被占， data.ratio加1，标记这个圆已被占。

            //获取当前圆的位置(当前鼠标上面所有的圆加x方向圆的个数)  这里用图更好说明WWW
            /**
             *    圆 圆 圆 圆 圆 圆
             *    圆 圆 圆 圆 圆 圆
             *    圆 圆 圆（鼠标在这里，那么位置就是上面的圆，加这一行横向的圆）
             */
            var p = Math.floor(tx / this.size) + Math.floor(ty / this.size) * this.colParts;
            if (p >= 0 && p < this.numParts) {
                this.ratio += this.parts[p];
                this.parts[p] = 0;
                if (!this.complete) {
                    if (this.ratio / this.numParts >= this.completeRatio) {
                        this.complete = true;
                        if (this.completeFunction != null && typeof this.completeFunction === "function") this.completeFunction(this.ratio / this.numParts);
                    }
                    else {
                        if (this.progressFunction != null && typeof this.progressFunction === "function") this.progressFunction(this.ratio / this.numParts);
                    }
                }
            }
        },
        clear() {
            //清除所有
            this.ctx.clearRect(0, 0, this.width, this.height);
        },
        reset() {
            this.ctx.globalCompositeOperation = "source-over";
            this.init()
        }
    }
}
</script>
