const baseSize = 12
function setRem() {
    var width = document.documentElement.clientWidth
    if (width > 500) {
        width = 500
    }
    const scale = width / 375 
    document.documentElement.style.fontSize = baseSize * scale + 'px'
}
setRem()
window.onresize = function () {
    setRem()
}