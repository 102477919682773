import { Image, Loading, CountDown, Popup } from 'vant';
import { createApp } from 'vue'
import App from './App.vue'
// 2. 引入组件样式
import 'vant/lib/style/css-variables.css'
import 'vant/lib/style/base.css';
import 'vant/lib/overlay/index.css';
import 'vant/lib/image/index.css';
import 'vant/lib/loading/index.css';
import 'vant/lib/popup/index.css';
import '@vant/touch-emulator';
import './rem.js'
const app = createApp(App)

// Method 1. via app.use
app.use(Image);
app.use(Loading);
app.use(CountDown);
app.use(Popup);
app.mount('#app')
