<template>
  <div class="FlipClock">
    <Flipper ref="flipperMinute1" />
    <Flipper ref="flipperMinute2" />
    <em>:</em>
    <Flipper ref="flipperSecond1" />
    <Flipper ref="flipperSecond2" />
  </div>
</template>

<script>
import Flipper from './Flipper'

export default {
  name: 'FlipClock',
  props: {
    seconds: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      timer: null,
      cd: 0,
      flipObjs: []
    }
  },
  watch: {
    seconds(value) {
      this.cd = value
      this.init()
      if (this.seconds > 0) {
        this.run()
      }
    },
  },
  components: {
    Flipper
  },
  mounted() {
    this.flipObjs = [
        this.$refs.flipperMinute1,
        this.$refs.flipperMinute2,
        this.$refs.flipperSecond1,
        this.$refs.flipperSecond2
    ]
  },
  methods: {
    // 初始化数字
    init() {
      clearInterval(this.timer)
      let cdStr = this.format(this.seconds);
       for (let i = 0; i < this.flipObjs.length; i++) {
         this.flipObjs[i].setFront(cdStr[i])
       }
    },
    // 开始计时
    run() {
      this.timer = setInterval(() => {
        if (this.cd == 0) {
          clearInterval(this.timer)
          this.$emit('finish')
          return
        }
        let prev = this.format(this.cd);
        this.cd--;
        let curr = this.format(this.cd);
        for (let i = 0; i < this.flipObjs.length; i++) {
          if (prev[i] === curr[i]) {
            continue
          }
          this.flipObjs[i].flipDown(prev[i], curr[i])
        }
      }, 1000)
    },
    format(seconds) {
			let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
			seconds -= 3600 * hour;
			let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
			seconds -= 60 * min;
			let sec = seconds >= 10 ? seconds : '0' + seconds;
			return min + '' + sec;
		}
  }
}
</script>

<style>
.FlipClock {
    text-align: center;
    line-height: 1;
}
.FlipClock .M-Flipper {
    /* margin: 0 3px; */
}
.FlipClock em {
    display: inline-block;
    line-height: 30px;
    font-size: 30px;
    font-style: normal;
    vertical-align: top;
}
</style>
