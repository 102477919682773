export const qs = function (key) {
    const s = new URLSearchParams(location.search)

    return s.get(key) || ''
}


export const touch = function (eleBar, options) {
    if (!eleBar) {
        return;
    }
    // 默认数据
    var defaults = {
        target: eleBar,
        bounding: window,
        edgeLock: true,
        onMove: function () { },
        onEnd: function () { }
    };

    options = options || {};

    var params = {};
    for (var key in defaults) {
        if (typeof options[key] != 'undefined') {
            params[key] = options[key];
        } else {
            params[key] = defaults[key];
        }
    }


    var objEventType = {
        start: 'mousedown',
        move: 'mousemove',
        end: 'mouseup'
    };

    if ('ontouchstart' in document) {
        objEventType = {
            start: 'touchstart',
            move: 'touchmove',
            end: 'touchend'
        };
    }

    // 坐标存储数据
    var store = {};
    eleBar.addEventListener(objEventType.start, function (event) {
        // IE 拖拽可能拖不动的处理
        if (!window.WeakMap || typeof document.msHidden != 'undefined') {
            event.preventDefault();
        }
        // 兼顾移动端
        if (event.touches && event.touches.length) {
            event = event.touches[0];
        }
        store.y = event.clientY;
        store.x = event.clientX;
        store.isMoving = true;
    });
    document.addEventListener(objEventType.move, function (event) {
        if (store.isMoving) {
            event.preventDefault();
            // 兼顾移动端
            
            if (event.touches && event.touches.length) {
                event = event.touches[0];
            }

            var distanceY = event.clientY;
            var distanceX = event.clientX;

            // 回调
            params.onMove(distanceX, distanceY);
        }
    }, {
        passive: false
    });
    document.addEventListener(objEventType.end, function () {
        if (store.isMoving) {
            store.isMoving = false;

            params.onEnd();
        }
    });
};
